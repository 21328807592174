/*
 * @Author: mjjh
 * @LastEditTime: 2023-09-05 21:20:48
 * @FilePath: \chagpt-shuowen\src\main.ts
 * @Description: main文件 配置项目
 */
import { createApp } from 'vue'
import App from './App.vue'
import { setupI18n } from './locales'
import { setupAssets, setupScrollbarStyle } from './plugins'
import VueLazyload from 'vue-lazyload'
import { setupStore } from './store'
// import VConsole from 'vconsole'
// if(localStorage.getItem('debuge')){
//   var vConsole = new VConsole();
//   vConsole.show(); // 显示vconsole
// }
import { setupRouter } from './router'
// var clickCount = 0;
// var lastClickTime = 0;
// document.addEventListener("click", function() {
//   var currentTime = new Date().getTime();
//   if (currentTime - lastClickTime < 1000) {
//     clickCount++; // 计数器自增

//     if (clickCount >= 10) {
//       console.log(clickCount)
//       var vConsole = new VConsole();
//       vConsole.show(); // 显示vconsole
//       localStorage.setItem('debuge', '1')
//     }
//   } else {
//     clickCount = 0
//   }

//   lastClickTime = currentTime; // 更新上一次点击时间
// });

async function setupApp() {
  const app = createApp(App).use(VueLazyload, {
    preLoad: 0.5,
    observer: true,
    loading: ''
  })
  // setupAssets 样式文件
  setupAssets()

  // setupScrollbarStyle 滚动条
  setupScrollbarStyle()

  // setupI18n 国际化插件
  setupI18n(app)

  // setupStore 使用pinia
  setupStore(app)

  // setupRouter 使用路由
  await setupRouter(app)

  app.mount('#app')
}

setupApp()
