
/*
 * @Author: mjjh
 * @LastEditTime: 2024-05-22 22:34:13
 * @FilePath: \chatgpt-shuowen\src\store\modules\auth\index.ts
 * @Description: 存储用户数据
 */
import { defineStore } from 'pinia'
import { getToken, removeToken, setToken } from './helper'
import { getMenu } from '@/api/login'
import { store } from '@/store'
import aa from '../../../assets/aa.jpg';
import output from  '../../../assets/output.png';
const menuUrlOptions = [
  {
    label: '创作',
    value: '/ai'
  },
  // {
  //   label: '绘画',
  //   value: '/draw'
  // },
  {
    label: '任务',
    value: '/user/task'
  },
  {
    label: '个人中心',
    value: '/user'
  },
  {
    label: '个人中心-充值',
    value: '/user/pay'
  },
  {
    label: '个人中心-卡密兑换',
    value: '/user/card'
  },
  {
    label: '个人中心-修改用户信息',
    value: '/user/edit'
  },
  {
    label: '个人中心-订单记录',
    value: '/user/order'
  },
  {
    label: '个人中心-联系客服',
    value: '/user/wx'
  },{
    label: '分销赚钱',
    value: '/team'
  },
  {
    value: '/team/fans'
  },
  {
    value: '/team/order'
  },  {
    value: '/team/share'
  },  {
    value: '/team/withdraw'
  },  {
    value: '/team/withdraw/list'
  },  {
    value: '/team/withdraw/detail'
  }
].map(item => item.value)

export interface AuthState {
  token: string | undefined,
  menuList: any,
  webConfig: any
}

export const useAuthStore = defineStore('auth-store', {
  state: (): AuthState => ({
    token: getToken(),
    menuList: JSON.parse(localStorage.getItem('menuList') || '[]'),
    webConfig: {
      title: '',
      indexPage: {
        title: "",
        content: ``,
      },
      indexDialogConfig: {
        title: "",
        wxCode: '',
      },
      noCountConfig: {
        title: "",
        wxCode: output,
      },
      kefuConfig: {
        title: "",
        wxCode: aa,
      },
    },
  }),

  actions: {

    setToken(token: string) {
      this.token = token
      setToken(token)
    },
    setWebConfig(data) {
			this.webConfig = {...this.webConfig, ...data}
		},
    async getMenuList(route) {
      const { data: menuData } = await getMenu()
      const menuUrl = menuData.userMenuVOList?.map(item =>item.menuUrl)??[]

      this.setMenuList((!menuData.roleId ? menuUrlOptions : menuUrl))
      const roleData = menuData.userSysInfoList?.[0]??{}
      const config = {
        title: roleData.siteName || '',
        isLoad: true,
        siteLogoUrl: roleData.siteLogoUrl,
        isPopUpTitle: roleData.isPopUpTitle,
        indexPage: {
          title: roleData.adTitle || '顶刊研习社',
          content: roleData.adContent || `<span style="color:#333;">为了更好体验，我们现在为您免费升级专属你的私人AI助理，<br />
          新平台地址，请收藏：<a href="https://m.xiaoai.club/" style="color: blue">https://m.xiaoai.club/</a>
          (支持PC和手机端访问)<br />
          老平台暂时不再更新维持到9月1号进行下线<br />
          使用过程中有任何问题可以加微信：DKYXS666，加入社群学习讨论。<br />
          声明：AI私人助理工具仅用于学习交流使用，不得用于其他非法用途，感谢支持！</span>`
        },
        indexDialogConfig: {
          title: roleData.popUpTitle || '长按识别二维码关注',
          wxCode: roleData.qrCodeUrl || aa
        },
        noCountConfig: {
          title: "您的额度为0，微信扫一扫加入会员获取更多额度",
          wxCode: roleData.noLimitQrCodeUrl || output
        },
        kefuConfig: {
          title:roleData.customerServiceWechat|| '长按识别二维码关注',
          wxCode: roleData.qrCodeUrl || aa
        }
      }
      document.title = `${roleData.siteName || ''} - ${route.meta?.title?.split(' - ')?.[1] || ''}`
      this.setWebConfig(config)
    },
    setMenuList(data) {
      this.menuList = data;
      localStorage.setItem('menuList', JSON.stringify(data))
    },
    removeMenuList() {
      localStorage.setItem('menuList', '[]')
    },

    removeToken() {
      this.token = undefined
      removeToken()
    },
  },
})

export function useAuthStoreWithout() {
  return useAuthStore(store)
}
