/*
 * @Author: vxy1142296352 1142296352@qq.com
 * @Date: 2023-06-13 22:42:32
 * @LastEditors: vxy1142296352 1142296352@qq.com
 * @LastEditTime: 2023-09-15 22:33:46
 * @FilePath: \src\store\modules\app\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { defineStore } from "pinia";
import type { AppState, Language, Theme } from "./helper";
import { getLocalSetting, setLocalSetting } from "./helper";
import aa from '../../../assets/aa.jpg';
import output from  '../../../assets/output.png';
import { store } from "@/store";

export const useAppStore = defineStore("app-store", {
	state: (): AppState => {
		const appState = getLocalSetting();
		return {
			...appState,
			webConfig: {
        title: '',
				indexPage: {
					title: "顶刊研习社",
					content: `<span style="color:#333;">为了更好体验，我们现在为您免费升级专属你的私人AI助理，<br />
    新平台地址，请收藏：<a href="https://m.xiaoai.club/" style="color: blue">https://m.xiaoai.club/</a>
    (支持PC和手机端访问)<br />
    老平台暂时不再更新维持到9月1号进行下线<br />
    使用过程中有任何问题可以加微信：DKYXS666，加入社群学习讨论。<br />
    声明：AI私人助理工具仅用于学习交流使用，不得用于其他非法用途，感谢支持！</span>`,
				},
				indexDialogConfig: {
					title: "长按识别二维码关注",
					wxCode: aa,
				},
				noCountConfig: {
					title: "您的额度为0，微信扫一扫加入会员获取更多额度",
					wxCode: output,
				},
				kefuConfig: {
					title: "长按识别二维码关注",
					wxCode: aa,
				},
			},
		};
	},
	actions: {
		setWebConfig(data) {
			this.webConfig = {...this.webConfig, ...data}
		},
		// 删除app模式下侧边栏的聊天室
		setSiderCollapsed(collapsed: boolean) {
			this.siderCollapsed = collapsed;
			this.recordState();
		},

		setTheme(theme: Theme) {
			this.theme = theme;
			this.recordState();
		},

		setLanguage(language: Language) {
			if (this.language !== language) {
				this.language = language;
				this.recordState();
			}
		},

		recordState() {
			setLocalSetting(this.$state);
		},
	},
});

export function useAppStoreWithOut() {
	return useAppStore(store);
}
