
<script setup lang="ts">
import { onMounted } from 'vue';
var $ = window.$
const emits = defineEmits(['validate-success'])
const init = () => {
    var currentCaptchaConfig: { startX?: any; startY?: any; startTime: any; trackArr: any; end: any; bgImageWidth: any; moveX?: any; movePercent: any; stopTime?: any; bgImageHeight?: any; sliderImageWidth?: any; sliderImageHeight?: any; };
    /** 是否打印日志 */

    function clearPreventDefault(event: { preventDefault: () => void; }) {
        if (event.preventDefault) {
            event.preventDefault();
        }
    }

    function clearAllPreventDefault($div: { each: (arg0: (index: any, el: { addEventListener: (arg0: string, arg1: (event: any) => void, arg2: boolean) => void; }) => void) => void; }) {
        $div.each(function (index: any, el: { addEventListener: (arg0: string, arg1: (event: any) => void, arg2: boolean) => void; }) {
            el.addEventListener('touchmove', clearPreventDefault, false);
        });
    }



    function initConfig(bgImageWidth: any, bgImageHeight: any, sliderImageWidth: any, sliderImageHeight: any, end: number) {
        currentCaptchaConfig = {
            startTime: new Date(),
            trackArr: [],
            movePercent: 0,
            bgImageWidth,
            bgImageHeight,
            sliderImageWidth,
            sliderImageHeight,
            end
        }
        return currentCaptchaConfig;
    }

    function down(event: { originalEvent: { targetTouches: any; }; targetTouches: any; pageX: any; pageY: any; }) {
        let targetTouches = event.originalEvent ? event.originalEvent.targetTouches : event.targetTouches;
        let startX = event.pageX;
        let startY = event.pageY;
        if (startX === undefined) {
            startX = Math.round(targetTouches[0].pageX);
            startY = Math.round(targetTouches[0].pageY);
        }
        currentCaptchaConfig.startX = startX;
        currentCaptchaConfig.startY = startY;

        const pageX = currentCaptchaConfig.startX;
        const pageY = currentCaptchaConfig.startY;
        const startTime = currentCaptchaConfig.startTime;
        const trackArr = currentCaptchaConfig.trackArr;
        trackArr.push({
            x: pageX - startX,
            y: pageY - startY,
            type: "down",
            t: (new Date().getTime() - startTime.getTime())
        });
        // pc
        window.addEventListener("mousemove", move);
        window.addEventListener("mouseup", up);
        // 手机端
        window.addEventListener("touchmove", move, false);
        window.addEventListener("touchend", up, false);
        doDown(currentCaptchaConfig);
    }

    function move(event: Touch) {
        if (event instanceof TouchEvent) {
            event = event.touches[0];
        }
        let pageX = Math.round(event.pageX);
        let pageY = Math.round(event.pageY);
        const startX = currentCaptchaConfig.startX;
        const startY = currentCaptchaConfig.startY;
        const startTime = currentCaptchaConfig.startTime;
        const end = currentCaptchaConfig.end;
        const bgImageWidth = currentCaptchaConfig.bgImageWidth;
        const trackArr = currentCaptchaConfig.trackArr;
        let moveX = pageX - startX;
        const track = {
            x: pageX - startX,
            y: pageY - startY,
            type: "move",
            t: (new Date().getTime() - startTime.getTime())
        };
        trackArr.push(track);
        if (moveX < 0) {
            moveX = 0;
        } else if (moveX > end) {
            moveX = end;
        }
        currentCaptchaConfig.moveX = moveX;
        currentCaptchaConfig.movePercent = moveX / bgImageWidth;
        doMove(currentCaptchaConfig);
    }

    function up(event: Touch) {
        window.removeEventListener("mousemove", move);
        window.removeEventListener("mouseup", up);
        window.removeEventListener("touchmove", move);
        window.removeEventListener("touchend", up);
        if (event instanceof TouchEvent) {
            event = event.changedTouches[0];
        }
        currentCaptchaConfig.stopTime = new Date();
        let pageX = Math.round(event.pageX);
        let pageY = Math.round(event.pageY);
        const startX = currentCaptchaConfig.startX;
        const startY = currentCaptchaConfig.startY;
        const startTime = currentCaptchaConfig.startTime;
        const trackArr = currentCaptchaConfig.trackArr;

        const track = {
            x: pageX - startX,
            y: pageY - startY,
            type: "up",
            t: (new Date().getTime() - startTime.getTime())
        }

        trackArr.push(track);
        valid(currentCaptchaConfig);
    }

    let currentCaptchaId: string | null = null;
    $(function () {
        clearAllPreventDefault($(".slider"));
        refreshCaptcha();
    })

    $("#slider-move-btn").mousedown(down);
    $("#slider-move-btn").on("touchstart", down);

    $("#slider-close-btn").click(() => {

    });

    $("#slider-refresh-btn").click(() => {
        refreshCaptcha();
    });

    function valid(captchaConfig: { startX?: any; startY?: any; startTime: any; trackArr: any; end?: any; bgImageWidth: any; moveX?: any; movePercent?: any; stopTime: any; bgImageHeight: any; sliderImageWidth?: any; sliderImageHeight?: any; templateImageWidth?: any; templateImageHeight?: any; }) {

        let data = {
            picCodeSessionId: currentCaptchaId,
            imageCaptchaTrack: {
                bgImageWidth: captchaConfig.bgImageWidth,
                bgImageHeight: captchaConfig.bgImageHeight,
                templateImageWidth: captchaConfig.templateImageWidth,
                templateImageHeight: captchaConfig.templateImageHeight,
                startSlidingTime: captchaConfig.startTime,
                entSlidingTime: captchaConfig.stopTime,
                trackList: captchaConfig.trackArr
            }
        };
        $.ajax({
            type: "POST",
            url: "/api/user/verify/slider",
            contentType: "application/json", //必须这样写
            dataType: "json",
            data: JSON.stringify(data),//schoolList是你要提交是json字符串
            success: function () {
                emits('validate-success', data)
                window.$message?.success('验证成功')
            },
            error: function () {
                window.$message?.success('验证失败')
                refreshCaptcha();
            }

        })

    }

    function refreshCaptcha() {
        $.get("/api/user/getSlider", function ({ data }) {
            reset();
            currentCaptchaId = data.picCodeSessionId;
            const bgImg = $("#bg-img");
            const sliderImg = $("#slider-img");
            bgImg.attr("src", data.imageCaptchaInfo.backgroundImage);
            sliderImg.attr("src", data.imageCaptchaInfo.templateImage);
            setTimeout(() => {
                initConfig(bgImg.width(), bgImg.height(), sliderImg.width(), sliderImg.height(), 206);
            }, 200)
        })
    }

    function doDown() {
        $("#slider-move-btn").css("background-position", "-5px 31.0092%")
    }


    function doMove(currentCaptchaConfig: { startX?: any; startY?: any; startTime?: any; trackArr?: any; end?: any; bgImageWidth?: any; moveX: any; movePercent?: any; stopTime?: any; bgImageHeight?: any; sliderImageWidth?: any; sliderImageHeight?: any; }) {
        const moveX = currentCaptchaConfig.moveX;
        $("#slider-move-btn").css("transform", "translate(" + moveX + "px, 0px)")
        $("#slider-img-div").css("transform", "translate(" + moveX + "px, 0px)")
    }
    function reset() {
        $("#slider-move-btn").css("background-position", "-5px 11.79625%")
        $("#slider-move-btn").css("transform", "translate(0px, 0px)")
        $("#slider-img-div").css("transform", "translate(0px, 0px)")
        currentCaptchaId = null;
    }

}

onMounted(() => {
    init()
})
</script>
<template>
    <div class="slider">
        <div class="content">
            <div class="bg-img-div">
                <img id="bg-img" src="" alt />
            </div>
            <div class="slider-img-div" id="slider-img-div">
                <img id="slider-img" src="" alt />
            </div>
        </div>
        <div class="slider-move">
            <div class="slider-move-track">
                拖动滑块完成拼图
            </div>
            <div class="slider-move-btn" id="slider-move-btn"></div>
        </div>
        <div class="bottom">
            <div class="refresh-btn" id="slider-refresh-btn"></div>
        </div>
    </div>
</template>
<style>
.slider {
    background-color: #fff;
    width: 278px;
    height: 285px;
    z-index: 999;
    box-sizing: border-box;
    padding: 9px;
    border-radius: 6px;
    box-shadow: 0 0 11px 0 #999999;
}

.slider .content {
    width: 100%;
    height: 159px;
    position: relative;
}

.bg-img-div {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(0px, 0px);
}

.slider-img-div {
    height: 100%;
    position: absolute;
    transform: translate(0px, 0px);
}

.bg-img-div img {
    width: 100%;
}

.slider-img-div img {
    height: 100%;
}

.slider .slider-move {
    height: 60px;
    width: 100%;
    margin: 11px 0;
    position: relative;
}

.slider .bottom {
    height: 19px;
    width: 100%;
}

.refresh-btn,
.close-btn,
.slider-move-track,
.slider-move-btn {
    background: url(../../../assets/sprite.1.2.4.png) no-repeat;
}

.refresh-btn,
.close-btn {
    display: inline-block;
}

.slider-move .slider-move-track {
    line-height: 38px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    color: #88949d;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.slider {
    user-select: none;
}

.slider-move .slider-move-btn {
    transform: translate(0px, 0px);
    background-position: -5px 11.79625%;
    position: absolute;
    top: -12px;
    left: 0;
    width: 66px;
    height: 66px;
}

.slider-move-btn:hover,
.close-btn:hover,
.refresh-btn:hover {
    cursor: pointer
}

.bottom .close-btn {
    width: 20px;
    height: 20px;
    background-position: 0 44.86874%;
}

.bottom .refresh-btn {
    width: 20px;
    height: 20px;
    background-position: 0 81.38425%;
}
</style>