<!--
 * @Author: vxy1142296352 1142296352@qq.com
 * @Date: 2023-08-30 22:42:39
 * @LastEditors: vxy1142296352 1142296352@qq.com
 * @LastEditTime: 2023-09-16 00:26:48
 * @FilePath: \src\components\wx-code\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <NModal :show="showWx" style="width: 90%; max-width:440px" closable>
    <div class="p-5 bg-white rounded dark:bg-slate-800">
      <div style="display:flex;justify-content:flex-end">
        <svg t="1686998884620" class="icon" @click="showWx = false" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" p-id="4298" width="24" height="24">
          <path
            d="M512 853.333a341.333 341.333 0 1 0 0-682.666 341.333 341.333 0 0 0 0 682.666z m0 85.334C276.352 938.667 85.333 747.648 85.333 512S276.352 85.333 512 85.333 938.667 276.352 938.667 512 747.648 938.667 512 938.667z"
            p-id="4299"></path>
          <path d="M374.315 315.733l328.832 328.832-60.331 60.331-328.832-328.875z" p-id="4300"></path>
          <path d="M706.56 375.595L377.728 704.469l-60.33-60.33 328.831-328.875z" p-id="4301"></path>
        </svg>
      </div>
      <h2 style="text-align: center">{{ authStore.webConfig.indexDialogConfig.title }}</h2>
      <img :src="authStore.webConfig.indexDialogConfig.wxCode" style="margin: 0 auto" />
      <NButton @click="hideInfo"
        style="width: 100%;max-width: 430px;margin: 0 auto;display: block;display:flex;justify-content: center"
        type="info">
        我已关注不再提醒
      </NButton>
    </div>
  </NModal>
</template>
<script setup lang="ts">
import { NModal, NButton } from 'naive-ui'
import { ref, watch } from 'vue';
import { useAuthStore } from '@/store'
const authStore = useAuthStore()
const showWx = ref(false)
const init = (val) => {
  showWx.value = !localStorage.getItem('is-load') && val.isPopUpTitle && val.isLoad
  localStorage.setItem('is-load', 'true')
}
watch(() => authStore.webConfig, (val) => {
  init(val)
})
const hideInfo = () => {
  showWx.value = false;
  localStorage.setItem('is-load', 'true')
}

</script>