<!--
 * @Author: mjjh
 * @LastEditTime: 2023-10-14 18:16:55
 * @FilePath: \chagpt-shuowen\src\views\chat\layout\register.vue
 * @Description:
-->
<script setup lang='ts'>
import { ref } from 'vue'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import { NButton, NForm, NFormItem, NInput, useMessage } from 'naive-ui'
// import { SvgIcon } from '@/components/index'
import { registerEmail } from '@/api/register'
import type {  registerModel } from '@/api/register'
import ImgCode from './code-img.vue'

const ms = useMessage()
const loading = ref(false)
const emits = defineEmits(['reg-success'])
// 验证码数据
// const picData = ref<getPicCodeType>({
//   picCodeBase64: '',
//   // 图形验证码会话ID，注册时需要传过来
//   picCodeSessionId: '',
// })
const key = ref(0)
// 表单相关
const formRef = ref<FormInst | null>(null)
const userInfo = ref({
  username: '',
  phoneNumber: '',
  passWord: '',
  reenteredPassWord: '',
  captcha: '',
  imageCaptchaTrack: '',
})
// 表单校验规则
const rules: FormRules = {
  username: {
    required: true,
    trigger: ['blur', 'input'],
    validator(rule, value) {
      // 自定义验证  规则
      const regMobile = /^[a-zA-Z0-9]+$/;
      if(!value) {
        return new Error('请输入用户名')
      }
      if (!value.match(regMobile))
        return new Error('请输入字母或者数字不要有空格等特殊字符')
      return true
    },
  },
  passWord: [
    {
      required: true,
      message: '请输入密码',
      trigger: ['input', 'blur'],
    },
  ],
  phoneNumber: [
    {
      required: true,
      trigger: ['blur', 'input'],
      validator(rule, value) {
        // 自定义验证  规则
        const regMobile = /^1[3456789]\d{9}$/
        if (!value)
          return new Error('请输入手机号')
        else if (!regMobile.test(value))
          return new Error('手机号格式不对')
        return true
      },
    }
  ],

  // 两次校验密码的逻辑
  reenteredPassWord: [
    {
      required: true,
      message: '请再次输入密码',
      trigger: ['input', 'blur'],
    },
    {
      validator: validatePasswordSame,
      message: '两次密码输入不一致',
      trigger: ['blur', 'password-input'],
    },
  ],
  imageCaptchaTrack: [
    {
      required: true,
      trigger: ['blur', 'input'],
      validator(rule, value) {
        // 自定义验证  规则

        if (!value)
          return new Error('请滑动滑块验证码')

        return true
      },
    },
  ],
}

/**
 * @description: 密码校验规则
 * @param {*} rule
 * @param {*} value
 * @return {*}
 */
function validatePasswordSame(rule: FormItemRule, value: string): boolean {
  return value === userInfo.value.passWord
}

/**
 * @description: 获取验证码数据
 * @return {*}
 */

// 校验验证码数据

/**
 * @description: 提交"token/secretKey"到后台校验
 */
async function handleVerify() {
  // 格式校验
  formRef.value?.validate((errors: any) => {
    if (!errors)
      pushClick()

    else
      ms.error('请正确填写输入框中的内容')
  })
}

interface Props {
  openId: string
}

const props = defineProps<Props>()

const validateSuccess = (data) => {
  userInfo.value.imageCaptchaTrack = data
}

async function pushClick() {
  // 取值
  const pushData: registerModel = {
    identity: userInfo.value.username,
    password: userInfo.value.passWord,
    phoneNumber: userInfo.value.phoneNumber,
    openID: props.openId,
    inviteKey: localStorage.getItem('inviteKey'),
    picCodeSessionId: userInfo.value.imageCaptchaTrack.picCodeSessionId,
    // registerType: RegisterType.Email,
  }

  try {
    // 开启加载状态
    loading.value = true
    // 发起请求
    // 注册
    await registerEmail(pushData)
    emits('reg-success')
    localStorage.removeItem('inviteKey')
    // 成功弹框
    // dialog.success({
    //   title: '注册成功!!!',
    //   content: `请到${userInfo.value.email}查看邮件确认`,
    //   maskClosable: false,
    //   closable: false,
    //   positiveText: '确定',
    //   onPositiveClick: () => {
    //     // 重载页面
    //     window.location.reload()
    //   },
    // })
    ms.success('注册成功')
  }
  catch (error: any) {
    // 错误弹框
    userInfo.value.imageCaptchaTrack = ''
    key.value++
  }
  finally {
    // 关闭加载状态
    loading.value = false
  }
}

/**
 * @description: 监听键盘Enter事件
 * @param {*} event
 */
function handlePress(event: KeyboardEvent) {

  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault()
    handleVerify()
  }
}
</script>

<template>
  <NForm ref="formRef" :model="userInfo" label-placement="top" :rules="rules">
    <NFormItem path="username" :label="$t('common.email')">
      <NInput v-model:value="userInfo.username" @keypress="handlePress"  placeholder="请输入字母或者数字不要有空格等特殊字符" />
    </NFormItem>
    <NFormItem path="phoneNumber" label="手机号">
      <NInput v-model:value="userInfo.phoneNumber" @keypress="handlePress" />
    </NFormItem>
    <NFormItem path="passWord" :label="$t('common.passWord')">
      <NInput v-model:value="userInfo.passWord" @keypress="handlePress" />
    </NFormItem>
    <NFormItem path="reenteredPassWord" label="确认密码">
      <NInput v-model:value="userInfo.reenteredPassWord" :disabled="!userInfo.passWord" @keypress="handlePress" />
    </NFormItem>
    <NFormItem path="imageCaptchaTrack" required :label="$t('common.captcha')">
      <ImgCode  @validate-success="validateSuccess" :key="key"/>
    </NFormItem>
  </NForm>
  <NButton block type="primary" :loading="loading" @click="handleVerify">
    注册
  </NButton>
  <div class="agree">
    <svg t="1692520135913" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
      p-id="4861" width="18" height="18">
      <path
        d="M512.1 62C263.9 61.9 62.3 263.4 62 511.7 61.7 759.8 263 961.4 511.5 962c248.3 0.6 450.5-201.5 450.5-450.1C962 263.7 760.4 62.1 512.1 62z m-76.3 638.2C373.2 637.6 310.3 574.7 248 512.5c11.4-11.4 23.2-23.1 34.7-34.5 49.4 49.5 100.1 100.1 150.6 150.7 103-103.1 204.6-204.7 305.5-305.6 13.4 13.4 25.5 25.5 37.2 37.1-112.8 112.7-226.7 226.5-340.2 340z"
        fill="#FFB814" p-id="4862"></path>
    </svg> 我已阅读并同意：<a href="/#/privacy"> 《隐私政策》</a>和<a href="/#/use">《使用协议》</a>
  </div>
</template>
<style lang="less" scoped>
.agree {
  padding: 10px;
  display: flex;

  a {
    color: #FFB814;
    cursor: pointer;
  }
}
</style>