/*
 * @Author: mjjh
 * @LastEditTime: 2023-09-01 22:44:16
 * @FilePath: \chagpt-shuowen\src\utils\request\index.ts
 * @Description:
 */
import type { AxiosProgressEvent, AxiosResponse, GenericAbortSignal } from 'axios'
import request from './axios'
import { useAuthStore } from '@/store'

export interface HttpOption {
  url: string
  data?: any
  method?: string
  headers?: any
  responseType?: any
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  signal?: GenericAbortSignal
  beforeRequest?: () => void
  afterRequest?: () => void
}

export interface Response<T = any> {
  data: T
  message: string | null
  status: string
  code: number
}

function http<T = any>(
  { url, data, method, headers, onDownloadProgress, signal, beforeRequest, afterRequest, responseType, hideInfo = false }: HttpOption,
) {
  const successHandler = (res: AxiosResponse<Response<T>>) => {
    if (res.data.code === 200 || typeof res.data === 'string' || responseType === 'blob')
      return res.data
   
    return Promise.reject(res.data)
  }

  const failHandler = (error: Response<Error>) => {
    const authStore = useAuthStore()
    afterRequest?.()
   
    if (error.response.status === 503) {
      if(!hideInfo) {
        window.$message.error(error.message || 'Error')
      }

      return Promise.reject(error.message || 'Error')
    }
    else if (error.response.status === 400) {
      if(error.response.data.code !== 400001) {
        if(!hideInfo) {
          window.$message.error(error.response.data.message || 'Error')
        }
       
      }
     
      return Promise.reject(error.response.data || 'Error')
    }
    else if (error.response.status === 401) {
     if(!hideInfo) {
      window.$message.error('登录过期,即将跳转登录页')
     }
      authStore.removeToken()
      window.location.replace('/#/login')
      return Promise.reject({ message: '登录过期,即将跳转登录页' })
    }
    else { 
      if(!hideInfo) {
        window.$message.error(error.message || 'Error')
      }
       return Promise.reject(error.message || 'Error') 
      }
  }

  beforeRequest?.()

  method = method || 'GET'

  const params = Object.assign(typeof data === 'function' ? data() : data ?? {}, {})

  return method === 'GET'
    ? request.get(url, { params, signal, onDownloadProgress, responseType }).then(successHandler, failHandler)
    : method === 'PUT' ? request.put(url, params, { headers, signal, onDownloadProgress }).then(successHandler, failHandler): 
     request.post(url, params, { headers, signal, onDownloadProgress }).then(successHandler, failHandler)
}

export function get<T = any>(
  { url, data, method = 'GET', onDownloadProgress, signal, beforeRequest, afterRequest, responseType }: HttpOption,
): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
    responseType
  })
}

export function post<T = any>(
  { url, data, method = 'POST', headers, onDownloadProgress, signal, beforeRequest, afterRequest, hideInfo = false }: HttpOption,
): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
    hideInfo
  })
}

export default post

export function put<T = any>(
  { url, data, method = 'PUT',headers, onDownloadProgress, signal, beforeRequest, afterRequest }: HttpOption,
): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
  })
}