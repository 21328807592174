import wx from "weixin-js-sdk";

export function isNumber<T extends number>(
	value: T | unknown
): value is number {
	return Object.prototype.toString.call(value) === "[object Number]";
}

export function isString<T extends string>(
	value: T | unknown
): value is string {
	return Object.prototype.toString.call(value) === "[object String]";
}

export function isBoolean<T extends boolean>(
	value: T | unknown
): value is boolean {
	return Object.prototype.toString.call(value) === "[object Boolean]";
}

export function isNull<T extends null>(value: T | unknown): value is null {
	return Object.prototype.toString.call(value) === "[object Null]";
}

export function isUndefined<T extends undefined>(
	value: T | unknown
): value is undefined {
	return Object.prototype.toString.call(value) === "[object Undefined]";
}

export function isObject<T extends object>(
	value: T | unknown
): value is object {
	return Object.prototype.toString.call(value) === "[object Object]";
}

export function isArray<T extends any[]>(value: T | unknown): value is T {
	return Object.prototype.toString.call(value) === "[object Array]";
}

export function isFunction<T extends (...args: any[]) => any | void | never>(
	value: T | unknown
): value is T {
	return Object.prototype.toString.call(value) === "[object Function]";
}

export function isDate<T extends Date>(value: T | unknown): value is T {
	return Object.prototype.toString.call(value) === "[object Date]";
}

export function isRegExp<T extends RegExp>(value: T | unknown): value is T {
	return Object.prototype.toString.call(value) === "[object RegExp]";
}

export function isPromise<T extends Promise<any>>(
	value: T | unknown
): value is T {
	return Object.prototype.toString.call(value) === "[object Promise]";
}

export function isSet<T extends Set<any>>(value: T | unknown): value is T {
	return Object.prototype.toString.call(value) === "[object Set]";
}

export function isMap<T extends Map<any, any>>(value: T | unknown): value is T {
	return Object.prototype.toString.call(value) === "[object Map]";
}

export function isFile<T extends File>(value: T | unknown): value is T {
	return Object.prototype.toString.call(value) === "[object File]";
}

export const appid = "wx5e44de9306e959d6";

export function wxAuth({ responseType = "code", scope = "snsapi_userinfo" }) {
	const redirectUri = encodeURIComponent(window.location.origin + "/#/loading");
	const authPageBaseUri = "https://open.weixin.qq.com/connect/oauth2/authorize";
	const authParams = `?appid=${appid}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}#wechat_redirect`;
	window.location.href = authPageBaseUri + authParams;
}

export function isWechatEnvironment() {
	return /MicroMessenger/i.test(window.navigator.userAgent);
}

export const generateNonceStr = () => {
	var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var length = 32;
	var nonceStr = "";

	for (var i = 0; i < length; i++) {
		nonceStr += chars.charAt(Math.floor(Math.random() * chars.length));
	}

	return nonceStr;
};

export const initConfig = (data, cb) => {
	wx.config({
		debug: false, //这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
		appId: data.appId,
		jsApiList: ["chooseWXPay"],
	});

	wx.ready(function () {
		wx.chooseWXPay({
			// appId: data.appId,
			timestamp: data.payTimeStamp,
			nonceStr: data.nonceStr,
			package: data.payPackage,
			signType: data.signType,
			paySign: data.paySign,
			success: function (response: { errMsg: string }) {
				// response.errMsg === 'chooseWXPay:ok'方式判断前端返回,微信团队郑重提示：
				// response.errMsg将在用户支付成功后返回ok，但并不保证它绝对可靠， 切记。
				cb && cb()
			},
			// 支付取消回调函数
			cancel: function (eerr) {
				console.log("取消支付", JSON.stringify(eerr));
			},
			// 支付失败回调函数
			fail: function (eerr) {
				console.log("支付失败", JSON.stringify(eerr));
			},
		});
	});
};
